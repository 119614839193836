import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`THE GOAL`}</strong><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`  `}</mtext></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`~~`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span></span></span></span></span>{` Compare the differential entropy computed analytically using the probability
density function (PDF) with those estimated using a collection of samples.`}</p>
    <h1>{`An Analytical Example`}</h1>
    <p>{`Consider a uniform distribution between `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`[`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mn parentName="mrow">{`1`}</mn><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`/`}</mi><mn parentName="mrow">{`2`}</mn><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`]`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`[0, 1/2]`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`1`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`/`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`]`}</span></span></span></span></span></p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`p`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`{`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.3599999999999999em",
                      "columnalign": "left left",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mn parentName="mstyle">{`2`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mrow parentName="mstyle"><mtext parentName="mrow">{` if `}</mtext><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`∈`}</mo><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`[`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                                "separator": "true"
                              }}>{`,`}</mo><mn parentName="mrow">{`1`}</mn><mi parentName="mrow" {...{
                                "mathvariant": "normal"
                              }}>{`/`}</mi><mn parentName="mrow">{`2`}</mn><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`]`}</mo></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mn parentName="mstyle">{`0`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mtext parentName="mstyle">{` otherwise. `}</mtext></mstyle></mtd></mtr></mtable></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`p(x) = \\begin{cases} 2&\\text{ if } x \\in [0, 1/2]\\\\ 0&\\text{ otherwise. } \\end{cases}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`p`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.0000299999999998em",
                  "verticalAlign": "-1.25003em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size4"
                  }}>{`{`}</span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-l"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.69em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.69em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3.008em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`2`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-2.25em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3.008em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`0`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.19em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "1em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "col-align-l"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.69em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.69em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3.008em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord text"
                                }}><span parentName="span" {...{
                                    "className": "mord"
                                  }}>{` if `}</span></span><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`x`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2777777777777778em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mrel"
                                }}>{`∈`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2777777777777778em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mopen"
                                }}>{`[`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`0`}</span><span parentName="span" {...{
                                  "className": "mpunct"
                                }}>{`,`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.16666666666666666em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`1`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`/`}</span><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`2`}</span><span parentName="span" {...{
                                  "className": "mclose"
                                }}>{`]`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-2.25em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3.008em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord text"
                                }}><span parentName="span" {...{
                                    "className": "mord"
                                  }}>{` otherwise. `}</span></span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.19em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></div>
    <p>{`The differential entropy is`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.24999999999999992em",
                  "columnalign": "right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                            "stretchy": "false"
                          }}>{`(`}</mo><mi parentName="mrow">{`u`}</mi><mo parentName="mrow" {...{
                            "stretchy": "false"
                          }}>{`)`}</mo></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mo parentName="mrow">{`−`}</mo><msubsup parentName="mrow"><mo parentName="msubsup">{`∫`}</mo><mn parentName="msubsup">{`0`}</mn><mrow parentName="msubsup"><mn parentName="mrow">{`1`}</mn><mi parentName="mrow" {...{
                                "mathvariant": "normal"
                              }}>{`/`}</mi><mn parentName="mrow">{`2`}</mn></mrow></msubsup><mn parentName="mrow">{`2`}</mn><mo parentName="mrow">{`∗`}</mo><mi parentName="mrow">{`log`}</mi><mo parentName="mrow">{`⁡`}</mo><mo parentName="mrow" {...{
                            "stretchy": "false"
                          }}>{`(`}</mo><mn parentName="mrow">{`2`}</mn><mo parentName="mrow" {...{
                            "stretchy": "false"
                          }}>{`)`}</mo><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`d`}</mi><mi parentName="mrow">{`x`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`log`}</mi><mo parentName="mrow">{`⁡`}</mo><mn parentName="mrow">{`2`}</mn></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`0.693`}</mn></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{aligned} H(u) &= - \\int_0^{1/2} 2 * \\log(2) \\mathrm d x \\\\ &= - \\log 2 \\\\ &= - 0.693 \\end{aligned}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "5.84985em",
                  "verticalAlign": "-2.674925em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "3.174925em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-5.174925em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.6379em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mopen"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`u`}</span><span parentName="span" {...{
                                "className": "mclose"
                              }}>{`)`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.1229750000000003em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.6379em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-1.6229750000000003em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.6379em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.674925em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "3.174925em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-5.174925em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.6379em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2777777777777778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2777777777777778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.16666666666666666em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mop"
                              }}><span parentName="span" {...{
                                  "className": "mop op-symbol large-op",
                                  "style": {
                                    "marginRight": "0.44445em",
                                    "position": "relative",
                                    "top": "-0.0011249999999999316em"
                                  }
                                }}>{`∫`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "1.6379000000000004em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-1.7880500000000001em",
                                            "marginLeft": "-0.44445em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`0`}</span></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3.8129000000000004em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`1`}</span><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`/`}</span><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.9119499999999999em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.16666666666666666em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`2`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222222222222222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mbin"
                              }}>{`∗`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222222222222222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mop"
                              }}>{`lo`}<span parentName="span" {...{
                                  "style": {
                                    "marginRight": "0.01389em"
                                  }
                                }}>{`g`}</span></span><span parentName="span" {...{
                                "className": "mopen"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`2`}</span><span parentName="span" {...{
                                "className": "mclose"
                              }}>{`)`}</span><span parentName="span" {...{
                                "className": "mord mathrm"
                              }}>{`d`}</span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.1229750000000003em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.6379em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2777777777777778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2777777777777778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.16666666666666666em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mop"
                              }}>{`lo`}<span parentName="span" {...{
                                  "style": {
                                    "marginRight": "0.01389em"
                                  }
                                }}>{`g`}</span></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.16666666666666666em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`2`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-1.6229750000000003em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.6379em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2777777777777778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2777777777777778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`0`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`.`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`6`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`9`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`3`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.674925em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`There are two ways to compute this numerically. The first is to use analytical
calculation from the distribution. We do so by discretizing the probability distribution
function into bins. We do so by normalizing the sequence of probabilities. This gives us
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`log`}</mi><mo parentName="mrow">{`⁡`}</mo><mn parentName="mrow">{`2`}</mn><mo parentName="mrow">{`=`}</mo><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`0.69`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H(x) = -\\log 2 = -0.69`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8888799999999999em",
                  "verticalAlign": "-0.19444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}>{`lo`}<span parentName="span" {...{
                  "style": {
                    "marginRight": "0.01389em"
                  }
                }}>{`g`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.72777em",
                  "verticalAlign": "-0.08333em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`6`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`9`}</span></span></span></span></span>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`H_d`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    ps_norm `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` ps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` ps_norm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}>{`xs `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`linspace`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1001`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
ps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ones`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1001`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
h_d `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` H_d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
doc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"analytical: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`h_d`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token format-spec"
              }}>{`0.2f`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`analytical: -0.69`}</code></pre></div>
    <h2>{`Can We Compute This Via `}<code parentName="h2" {...{
        "className": "text"
      }}>{`scipy entropy`}</code>{`?`}</h2>
    <p>{`The `}<code parentName="p" {...{
        "className": "text"
      }}>{`scipy.stats.entropy`}</code>{` function computes the `}<code parentName="p" {...{
        "className": "text"
      }}>{`Shannon entropy`}</code>{` of a discrete distribution. The scipy entropy does not assume that the probability is normalized,
so it normalizes the `}<code parentName="p" {...{
        "className": "text"
      }}>{`ps`}</code>{` internally first. This means that in order to convert this to the
differential entropy, we need to scale the result by the log sum.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}>{`h_d_analytic `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stats`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`entropy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
doc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"analytic entropy w/ scipy: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`h_d_analytic`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token format-spec"
              }}>{`0.3f`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`analytic entropy w/ scipy: -0.693`}</code></pre></div>
    <p>{`This shows that we can get the discrete `}<code parentName="p" {...{
        "className": "text"
      }}>{`scipy.stats.entropy`}</code>{` to agree with our differential entropy function `}<code parentName="p" {...{
        "className": "text"
      }}>{`H_d:ps -> R`}</code>{` that is defined analytically.`}</p>
    <br />
    <h1>{`Estimating Entropy Using Samples from A Probability Density Function`}</h1>
    <p>{`When we do not have access to the PDF, we can also estimate the entropy from a set of samples. We can do this using the `}<code parentName="p" {...{
        "className": "text"
      }}>{`differential_entropy`}</code>{` function from the `}<code parentName="p" {...{
        "className": "text"
      }}>{`stats`}</code>{` package in `}<code parentName="p" {...{
        "className": "text"
      }}>{`scipy`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` scipy `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` stats

samples `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`random`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`uniform`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`10001`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
doc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"verify the distribution: min: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`samples`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token builtin"
              }}>{`min`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token format-spec"
              }}>{`0.2f`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`, max: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`samples`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "token builtin"
              }}>{`max`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token format-spec"
              }}>{`0.2f`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`verify the distribution: min: 0.00, max: 0.50`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}>{`h_d_sample `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` stats`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`differential_entropy`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`samples`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
doc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"sample-based: `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`h_d_sample`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token format-spec"
              }}>{`0.3f`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`sample-based: -0.702`}</code></pre></div>
    <br />
    <h1>{`Effect of Sampling On Differential Entropy`}</h1>
    <p><strong parentName="p">{`THE GOAL`}</strong><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`  `}</mtext></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`~~`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span></span></span></span></span>{` vary the number of samples from the distribution, and compare
the sample-based estimate against those computed from the analytical solution.
This is done on the scipy documentation page `}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`.`}</p>
    <p>{`Again, consider the uniform distribution from above.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`H_d`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    ps_norm `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` ps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` ps_norm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`get_H_d`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    xs `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`linspace`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    ps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ones`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
    h_d `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` H_d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    doc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"N=`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`N`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{` => h_d=`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`h_d`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token format-spec"
              }}>{`0.2f`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` n `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`101`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`201`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`401`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`801`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1601`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    get_H_d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`n`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`N=101 => h_d=-0.69
N=201 => h_d=-0.69
N=401 => h_d=-0.69
N=801 => h_d=-0.69
N=1601 => h_d=-0.69`}</code></pre></div>
    <p>{`The differential entropy does not depend on the number of points in the
discretized distribution as long as the discretization is done properly.`}</p>
    <h1>{`Effect of Change of Variable On Differential Entropy`}</h1>
    <p><strong parentName="p">{`THE GOAL`}</strong><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`  `}</mtext></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`~~`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mspace nobreak"
              }}>{` `}</span></span></span></span></span>{` Investigate the effect of the change of variable, by varying the
scale of the distribution and looking at the entropy. We can look at both
the differential entropy analytically, and the sample-based estimates `}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`.`}</p>
    <p>{`Now consider a more general case, a uniform distribution between `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`[`}</mo><mi parentName="mrow">{`a`}</mi><mo parentName="mrow" {...{
                    "separator": "true"
                  }}>{`,`}</mo><mi parentName="mrow">{`b`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`]`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`[a, b]`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mpunct"
              }}>{`,`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`]`}</span></span></span></span></span></p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`p`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`{`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.3599999999999999em",
                      "columnalign": "left left",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mfrac parentName="mstyle"><mn parentName="mfrac">{`1`}</mn><mrow parentName="mfrac"><mi parentName="mrow" {...{
                                  "mathvariant": "normal"
                                }}>{`∣`}</mi><mi parentName="mrow">{`b`}</mi><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`a`}</mi><mi parentName="mrow" {...{
                                  "mathvariant": "normal"
                                }}>{`∣`}</mi></mrow></mfrac></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mrow parentName="mstyle"><mtext parentName="mrow">{`if `}</mtext><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`∈`}</mo><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`[`}</mo><mi parentName="mrow">{`a`}</mi><mo parentName="mrow" {...{
                                "separator": "true"
                              }}>{`,`}</mo><mi parentName="mrow">{`b`}</mi><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`]`}</mo></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mn parentName="mstyle">{`0`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mtext parentName="mstyle">{`otherwise. `}</mtext></mstyle></mtd></mtr></mtable></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`p(x) = \\begin{cases} \\frac 1 {\\vert b - a \\vert} & \\text{if } x \\in [a, b] \\\\ 0 & \\text{otherwise. } \\end{cases}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`p`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.0000299999999998em",
                  "verticalAlign": "-1.25003em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size4"
                  }}>{`{`}</span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-l"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.734em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.734em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3.008em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mopen nulldelimiter"
                                  }}></span><span parentName="span" {...{
                                    "className": "mfrac"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.845108em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.655em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`∣`}</span><span parentName="span" {...{
                                                  "className": "mord mathnormal mtight"
                                                }}>{`b`}</span><span parentName="span" {...{
                                                  "className": "mbin mtight"
                                                }}>{`−`}</span><span parentName="span" {...{
                                                  "className": "mord mathnormal mtight"
                                                }}>{`a`}</span><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`∣`}</span></span></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.23em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "frac-line",
                                              "style": {
                                                "borderBottomWidth": "0.04em"
                                              }
                                            }}></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.394em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "3em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`1`}</span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.52em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                    "className": "mclose nulldelimiter"
                                  }}></span></span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-2.206em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3.008em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`0`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.234em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "1em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "col-align-l"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.734em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.734em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3.008em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord text"
                                }}><span parentName="span" {...{
                                    "className": "mord"
                                  }}>{`if `}</span></span><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`x`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2777777777777778em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mrel"
                                }}>{`∈`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2777777777777778em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mopen"
                                }}>{`[`}</span><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`a`}</span><span parentName="span" {...{
                                  "className": "mpunct"
                                }}>{`,`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.16666666666666666em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`b`}</span><span parentName="span" {...{
                                  "className": "mclose"
                                }}>{`]`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-2.206em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3.008em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord text"
                                }}><span parentName="span" {...{
                                    "className": "mord"
                                  }}>{`otherwise. `}</span></span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.234em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></div>
    <p>{`The differential entropy is `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`log`}</mi><mo parentName="mrow">{`⁡`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`(`}</mo><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`∣`}</mi><mi parentName="mrow">{`b`}</mi><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`a`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`∣`}</mi><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`)`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\log \\left(\\vert b - a \\vert\\right)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}>{`lo`}<span parentName="span" {...{
                  "style": {
                    "marginRight": "0.01389em"
                  }
                }}>{`g`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord"
                }}>{`∣`}</span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`b`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222222222222222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`−`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222222222222222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`a`}</span><span parentName="span" {...{
                  "className": "mord"
                }}>{`∣`}</span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`)`}</span></span></span></span></span></span>{`. When
`}<code parentName="p" {...{
        "className": "text"
      }}>{`a=0`}</code>{` and `}<code parentName="p" {...{
        "className": "text"
      }}>{`b=0.5`}</code>{`, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`x`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`log`}</mi><mo parentName="mrow">{`⁡`}</mo><mn parentName="mrow">{`2`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H(x) = - \\log 2`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8888799999999999em",
                  "verticalAlign": "-0.19444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}>{`lo`}<span parentName="span" {...{
                  "style": {
                    "marginRight": "0.01389em"
                  }
                }}>{`g`}</span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.16666666666666666em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span></span></span></span></span>{`.`}</p>
    <p>{`We can verify this numerically. First let's define the entropy function`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`H_d`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    ps_norm `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` ps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token builtin"
          }}>{`sum`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` ps_norm`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`We can plot the delta against `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`log`}</mi><mo parentName="mrow">{`⁡`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow">{`b`}</mi><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`a`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\log(b - a)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop"
              }}>{`lo`}<span parentName="span" {...{
                  "style": {
                    "marginRight": "0.01389em"
                  }
                }}>{`g`}</span></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span></span></span></span></span>{` -- it turned out to be zero
across the range variants.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "python"
      }}><code parentName="pre" {...{
          "className": "python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`get_H_d`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` N`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`201`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    xs `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`linspace`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    ps `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ones`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`N`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`b `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    h_d `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` H_d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    delta `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` h_d `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`log`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`b `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` a`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    doc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`print`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string-interpolation"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`f"a=`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`a`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`, b=`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`b`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{` => h_d=`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`h_d`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "token format-spec"
              }}>{`0.2f`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`, δ=`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{`delta`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`"`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` b `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    get_H_d`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` b`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "text"
      }}><code parentName="pre" {...{
          "className": "text"
        }}>{`a=0, b=0.25 => h_d=-1.39, δ=0.0
a=0, b=0.5 => h_d=-0.69, δ=0.0
a=0, b=1 => h_d=-0.00, δ=-0.0
a=0, b=2 => h_d=0.69, δ=0.0
a=0, b=4 => h_d=1.39, δ=0.0`}</code></pre></div>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "https://docs.scipy.org/doc/scipy/reference/generated/scipy.stats.differential_entropy.html"
          }}>{`scipy.org ... /scipy.stats.differential_entropy`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      